const theme = {
  colors: {
    text: "#141414",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#141414",
    secondary: "#DBD7D2",
    light: "#ffffff",
    dark: "#141414",
    lightGrey: "#999999",
  },
  fonts: {
    body: '"Archivo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Archivo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  breakpoints: ["48em", "65em", "90em"], // metti nel boilerplate
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 160, 192, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 80],
  fontWeights: {
    body: 300,
    heading: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1,
  },
  sizes: {
    container: 1680,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    footerTitle: {
      fontSize: "10px",
      letterSpacing: "2px",
      color: "#999999",
      textTransform: "uppercase",
    },
    kicker: {
      my: 3,
      color: "primary",
      width: "fit-content",
      maxWidth: "fit-content",
      minWidth: "fit-content",
      letterSpacing: "-1px",
      fontSize: "20px",
      fontWeight: 400,
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "light",
      letterSpacing: "-2.4px",
    },
    h1: {
      fontSize: [7, 8],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "light",
      mb: 3,
    },
    h2: {
      letterSpacing: "-2.4px",
      fontSize: [7, 8],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "light",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "400",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  forms: {
    input: {
      fontSize: [3],
      transition: "all 0.3s ease-in-out",
      outline: "none",
      "&:focus": {
        borderColor: "light",
      },
    },
    select: {
      transition: "all 0.3s ease-in-out",
      outline: "none",
      "&:focus": {
        borderColor: "light",
      },
    },
    textarea: {
      transition: "all 0.3s ease-in-out",
      outline: "none",
      "&:focus": {
        borderColor: "light",
      },
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    lg: {
      maxWidth: "1920px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      textRendering: "optimizeLegibility",
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "light",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
